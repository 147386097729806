import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Jeff Weisman</h1>
        <h3>Personal Website</h3>
        <h3>Hello world</h3>
      </header>
    </div>
  );
}

export default App;
